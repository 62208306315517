.fill-primary-thematic-colour-100 {
  background: RGB(var(--primary-thematic-colour-100));
}

.fill-primary-thematic-colour-200 {
  background: RGB(var(--primary-thematic-colour-200));
}

.fill-secondary-thematic-colour-100 {
  background: RGB(var(--secondary-thematic-colour-100));
}

.fill-tertiary-thematic-colour-100 {
  background: RGB(var(--tertiary-thematic-colour-100));
}

.fill-clear {
  background: rgba(0,0,0,0);
}

.fill-primary-background-colour-100 {
  background: RGB(var(--primary-background-colour-100));
}
