.text-primary-thematic-colour-100 {
  color: RGB(var(--primary-thematic-colour-100));
}

.text-primary-thematic-colour-200 {
  color: RGB(var(--primary-thematic-colour-200));
}

.text-secondary-thematic-colour-100 {
  color: RGB(var(--secondary-thematic-colour-100));
}

.text-tertiary-thematic-colour-100 {
  color: RGB(var(--tertiary-thematic-colour-100));
}

.text-primary-background-colour-100 {
  color: RGB(var(--primary-background-colour-100));
}
