:root {
  --primary-background-colour-100: 255, 255, 255;
  --primary-background-colour-200: 232, 232, 232;
  --primary-background-colour-300: 176, 176, 176;
  --primary-background-colour-400: 128, 128, 128;

  --primary-foreground-colour-100: 30, 30, 30;

  --primary-thematic-colour-100: 84, 80, 125;
  --primary-thematic-colour-200: 137, 135, 158;

  --secondary-thematic-colour-100: 238, 118, 93;
  --secondary-thematic-colour-200: 236, 138, 117;

  --tertiary-thematic-colour-100: 54, 123, 118;
  --tertiary-thematic-colour-200: 71, 160, 153;

  color: RGB(var(--primary-foreground-colour-100));
}
